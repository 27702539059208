import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import "./plugins/firebase";
import VueSkycons from "vue-skycons";
import VueJsonToCsv from 'vue-json-to-csv';
import VueI18n from "vue-i18n";
import i18n from './i18n'
import mixins from 'vuetify-multiple-draggable-dialogs';
import VueGtag from 'vue-gtag';
import VueApexCharts from 'vue-apexcharts'
export const EventBus = new Vue();


Vue.use(VueSkycons, {
  color: "#1e88e5",
});

Vue.config.productionTip = false;
Vue.use(Vuebar);
Vue.use(VueJsonToCsv);
Vue.use(VueI18n);
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.mixin(mixins);

Vue.use(VueGtag, {
  config: {id: process.env.VUE_APP_GA_ID}
}, router);

new Vue({
  vuetify,
  store,
  i18n,
  router,
  render: (h) => h(App)
}).$mount("#app");
