import Vue from "vue";
import Router from "vue-router";
// import goTo from "vuetify/es5/services/goto";
import {auth} from "@/plugins/firebase"
// import {onAuthStateChanged} from "firebase/auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  /*scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },*/
  // This is for the scroll top when click on any router link
  routes: [
    { path: "/", redirect: "/home", name: "root", component: () => import("@/layouts/full-layout/Layout"),
      meta: {title:'Home', requiresAuth: false},
      children: [
        {name: "Home", path: "/home", component: () => import("@/views/Home"),
          meta: {title:'Home', requiresAuth: false}},
        {name: "Account", path: "/account", component: () => import("@/views/Account"),
          meta: {title:'Account', requiresAuth: true}},
        {name: "DataSearch", path: "/analysis/datasearch", component: () => import("@/views/DataSearch"),
          meta: {title:'Avgidea Data Search', requiresAuth: true}},
        {name: "QueryEditor", path: "/analysis/queryeditor", component: () => import("@/views/QueryEditor"),
          meta: {title:'Avgidea Query Editor', requiresAuth: true}},
        {name: "Report", path: "/analysis/report", component: () => import("@/views/Report"),
          meta: {title:'Avgidea Report', requiresAuth: true}},
        {name: "Sentiment", path: "/ml/sentiment", component: () => import("@/views/Sentiment"),
          meta: {title:'Avgidea Sentiment Analysis', requiresAuth: true}},
        {name: "Publisher", path: "/publisher/data", component: () => import("@/views/Publisher"),
          meta: {title:'Avgidea Publisher', requiresAuth: true}},
        {name: "SiteRegistration", path: "/publisher/site", component: () => import("@/views/SiteRegistration"),
          meta: {title:'Site Registration', requiresAuth: true}},
        {name: "Keyword", path: "/social/keyword", component: () => import("@/views/Keyword"),
          meta: {title:'Keyword', requiresAuth: true}},
        {name: "Social", path: "/social/analyze", component: () => import("@/views/Social"),
          meta: {title:'Avgidea Social', requiresAuth: true}},
        {name: "SocialAccount", path: "/social/account", component: () => import("@/views/SocialAccount"),
          meta: {title:'Social Account', requiresAuth: true}},
        {name: "EnitySet", path: "/exchange/entityset", component: () => import("@/views/EntitySet"),
          meta: {title:'Entity Set', requiresAuth: true}},
        {name: "Exchange", path: "/exchange/search", component: () => import("@/views/Exchange"),
          meta: {title:'Exchange', requiresAuth: true}},
        {name: "Storage", path: "/storage", component: () => import("@/views/Storage"),
          meta: {title:'Storage', requiresAuth: true}},
        {name: "Function", path: "/function/editor", component: () => import("@/views/Function"),
          meta: {title:'Storage', requiresAuth: true}},
        {name: "Scheduler", path: "/function/scheduler", component: () => import("@/views/Scheduler"),
          meta: {title:'Scheduler', requiresAuth: true}},
        //{name: "Flow", path: "/function/flow", component: () => import("@/views/Flow"),
        //  meta: {title:'Flow', requiresAuth: true}},
        {name: "Target", path: "/ads/target", component: () => import("@/views/Target"),
          meta: {title:'Target list', requiresAuth: true}},
        {name: "TargetAnalysis", path: "/ads/targetanalysis", component: () => import("@/views/TargetAnalysis"),
          meta: {title:'Target analysis', requiresAuth: true}},
        {name: "Price", path: "/price", component: () => import("@/views/Price"),
          meta: {title:'Avgidea Data Platform Pricing', requiresAuth: false}},
        {name: "Admin", path: "/_admin", component: () => import("@/views/Admin"),
          meta: {title:'Admin', requiresAuth: true}},
        {name: "Passcode", path: "/passcode", component: () => import("@/views/Passcode"),
          meta: {title:'Passcode', requiresAuth: true}},
      ],
      /* meta: { title: "test", requiresAuth: true }, */
    },

    { path: "/authentication", component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        { name: "AdpLogin", path: "adplogin",
          component: () => import("@/views/authentication/AdpLogin"),
        },
        { name: "AdpRegister", path: "adpRegister",
          component: () => import("@/views/authentication/AdpRegister"),
        },
      ],
    },
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // console.log("BeforeResolve - Navigating to:", to.path);
  if (to.name) {
    NProgress.start(800);
  }
  next();
});

router.beforeEach( async(to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  let calledNext = false;

  if (requiresAuth) {
    const user = await new Promise((resolve) => {
      auth.onAuthStateChanged((user) => {
        if (!calledNext) {
          calledNext = true; // Prevent multiple calls
          resolve(user);
        }
      });
    });
    if (user) {
      next();
    } else {
      console.log("fullpath: ", to.fullpath);
      next({
        path: '/authentication/adplogin',
        query: {redirect: to.fullPath}
      })
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
